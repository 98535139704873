var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SMListTemplate',{attrs:{"prefix":"Tanımlar","title":"Kampanya Listesi"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('v-icon',{directives:[{name:"permission",rawName:"v-permission",value:({
          permissions: ['campaigns-delete'],
        }),expression:"{\n          permissions: ['campaigns-delete'],\n        }"}],staticClass:"mr-2",attrs:{"color":"error cursor-pointer","dark":"","disabled":!_vm.hasCampaign},on:{"click":_vm.showDeleteCampaignModal}},[_vm._v(" mdi-delete ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"cursor-pointer","dark":""},on:{"click":() => _vm.fetchCampaigns()}},'v-icon',attrs,false),on),[_vm._v(" mdi-reload ")])]}}])},[_c('span',[_vm._v("Yenile")])]),_c('v-text-field',{staticClass:"mx-4 mt-0 pt-0",attrs:{"label":"Ara","dark":"","hide-details":"auto","single-line":"","append-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('router-link',{attrs:{"to":{ name: 'def-campaigns-edit', query: { editable: true } }}},[_c('v-btn',{directives:[{name:"permission",rawName:"v-permission",value:({
            permissions: ['campaigns-create'],
          }),expression:"{\n            permissions: ['campaigns-create'],\n          }"}],attrs:{"elevation":"0","color":"success"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Yeni Kampanya ")],1)],1)],1)]},proxy:true},{key:"table",fn:function(){return [_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.campaigns,"search":_vm.search,"single-select":true,"sort-by":"system","loading":_vm.loading,"sort-desc":"true","items-per-page":100,"show-select":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('router-link',{attrs:{"to":{
                name: 'def-campaigns-edit',
                params: { id: item.id },
                query: { editable: true },
              }}},[_c('v-icon',_vm._g(_vm._b({directives:[{name:"permission",rawName:"v-permission",value:({
                  permissions: ['campaigns-update'],
                }),expression:"{\n                  permissions: ['campaigns-update'],\n                }"}],attrs:{"color":"warning","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Düzenle")])])]}},{key:"item.name",fn:function({ item }){return [_c('router-link',{directives:[{name:"permission",rawName:"v-permission",value:({
            permissions: ['campaigns-read'],
          }),expression:"{\n            permissions: ['campaigns-read'],\n          }"}],attrs:{"to":{
            name: 'def-campaigns-edit',

            params: { id: item.id },
            query: { editable: false },
          }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.channel_id",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'def-channels-edit',
            params: { id: item.channel_id },
            query: { editable: false },
          }}},[_vm._v(" "+_vm._s(item.channel ? item.channel.name : "")+" ")])]}},{key:"item.url",fn:function({ item }){return [_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.url))])]}},{key:"item.description",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.description.length > 35 ? item.description.substring(0, 35) + " ..." : item.description)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])])]}}]),model:{value:(_vm.selectedCampaign),callback:function ($$v) {_vm.selectedCampaign=$$v},expression:"selectedCampaign"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }